import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { Link, Text } from '@primer/react';
import { Link as GatsbyLink } from 'gatsby';
import { AccessibilityLink } from '~/src/components/accessibility-link';
import { RailsProvider, RailsComponentLink } from '~/src/components/rails-provider';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <img src="https://github.com/primer/design/assets/586552/a3f4a112-bb48-44c8-9c57-ab011a3cd032" role="presentation" width="960" alt="Pagehead component" />
    <RailsProvider mdxType="RailsProvider">
      <h2>{`Usage`}</h2>
      <p>{`Pagehead is a heading component for the title and start of a given page section. The same component in our Rails framework is called `}<RailsComponentLink name="Subhead" mdxType="RailsComponentLink">{`Subhead`}</RailsComponentLink>{`.`}</p>
      <h3>{`Options`}</h3>
      <p><strong parentName="p">{`Basic pagehead`}</strong>{`: The basic pagehead is the simplest form of the pagehead component which shows only a text layer.`}</p>
      <p><strong parentName="p">{`Pagehead with avatars and actions`}</strong>{`: Avatars can be included next to the pagehead title, and actions can be added after the title.`}</p>
      <img src="https://github.com/primer/design/assets/586552/b972eb5b-9af5-46dc-bf2b-6d3c2dc4ea53" role="presentation" width="960" alt="Pagehead component showing variations including avatars and actions" />
      <h2>{`Accessibility`}</h2>
      <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="Pagehead" mdxType="AccessibilityLink" />
    </RailsProvider>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      